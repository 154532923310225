import './description_field.scss'
import { AddPhotoAlternate } from '@mui/icons-material';

interface DescriptionFieldProps { placeholder: string; value: string; onChange: React.ChangeEventHandler }

const DescriptionField: React.FC<DescriptionFieldProps> = ({ placeholder, value, onChange }) => {
    return (
        <div className='description-field'>
            <textarea
                rows={10}
                className='input-text'
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
            <button className="description-field__add-image" type="submit"><AddPhotoAlternate /></button>
        </div>
    );
};

export default DescriptionField;
