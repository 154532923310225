import { useEffect } from 'react';
import '../../styles/login.scss';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { GetCodeSchema, getCodeSchema } from '../../schemas/login/get-code-schema';
import { zodResolver } from '@hookform/resolvers/zod';
import InputMask from 'react-input-mask';


function GetCodePassword() {
    useEffect(() => {
        document.body.style.backgroundColor = 'white';
        return () => {
            document.body.style.backgroundColor = '#F3F5F9';
        };
    }, []);

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<GetCodeSchema>({
        resolver: zodResolver(getCodeSchema),
    });

    const onSubmit: SubmitHandler<
        GetCodeSchema
    > = async data => {
        navigate('/login/confirm-code-password');
    };

    return (
        <div className='container'>
            <div className="login-container">
                <div className="side">
                    <p className="login-logo">Meet.ups</p>
                    <img className='login-img' src="/password.svg" alt="Logo" />
                </div>
                <div className="side right-side">
                    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                        <p className="login-title">Esqueci a senha</p>

                        <div className="login-inputs">

                            <div>
                                <p className='login-label'>E-mail</p>
                                <input
                                    className={`input-text ${errors.email ? 'error' : ''}`}
                                    type="email"
                                    placeholder="Entrar com e-mail fornecido"
                                    {...register("email")} />
                                {errors.email && (
                                    <p className="input-label-error">
                                        {errors.email.message}
                                    </p>
                                )}
                                <p className='login-sub-label'>Você receberá um código no e-mail cadastrado</p>
                            </div>

                            <div className="separator">ou</div>

                            <div>
                                <p className='login-label'>Celular</p>
                                <InputMask
                                    className={`input-text ${errors.email ? 'error' : ''}`}
                                    mask={"+55 (99) 99999-9999"}
                                    maskChar={null}
                                    {...register("celular")} />
                                {errors.celular && (
                                    <p className="input-label-error">
                                        {errors.celular.message}
                                    </p>
                                )}
                                <p className='login-sub-label'>Você receberá um código no telefone cadastrado</p>
                            </div>

                            <div className='login-buttons'>
                                <button className='primary-button' type='submit'>
                                    Enviar codigo
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default GetCodePassword;
