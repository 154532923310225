import { AddCircleOutline, WhatsApp } from '@mui/icons-material';
import '../styles/messages.scss'
import { MessagesTableSchema } from '../schemas/messages/messages-table';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';
import CustomTable from '../components/core/custom_table';

const columnHelper = createColumnHelper<MessagesTableSchema>();

const MessagesPage = () => {

    const columns = [
        columnHelper.accessor('mensagem', {
            header: 'Mensagem',
            footer: props => props.column.id,
            cell: info => {
                const mensagem = info.row.original.mensagem;

                return (
                    <div className='td_name'> <span className='td_name_icon'><WhatsApp /></span> {mensagem}</div>
                );
            },
        }),
        columnHelper.accessor('enviado', {
            header: 'Enviado para',
            footer: props => props.column.id,
        }),
        columnHelper.accessor('telefone', {
            header: 'Telefone',
            footer: props => props.column.id,
        }),
        columnHelper.accessor('tipo', {
            header: 'Tipo',
            footer: props => props.column.id,
            cell: info => {
                const tipo = info.row.original.tipo;

                return (
                    <div className="">
                        <span className='tag new'>{tipo}</span>
                    </div>
                );
            },
        }),
    ];

    const data = React.useMemo(
        () => [
            { id: 1, mensagem: 'Agendamento cancelado', enviado: 'Anna Carolina Xavier Moura', telefone: '(11) 98765-4321', tipo: 'Cancelamento' },
            { id: 2, mensagem: 'Agendamento cancelado', enviado: 'Anna Carolina Xavier Moura', telefone: '(11) 98765-4321', tipo: 'Novo Agendamento' },
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="messages">
            <CustomTable table={table} />

            <div className="messages__create-button">
                <button
                    value={"Cadastrar nova mensagem"}
                >
                    <AddCircleOutline />
                </button>
            </div>
        </div>
    );
};

export default MessagesPage;