import { EventAvailable } from '@mui/icons-material';
import './calendar.scss';
import { motion } from 'framer-motion';

interface ColorItem {
    color: string;
}

interface Entry {
    day: string;
    dayOfWeek: string;
    schedule: ColorItem[];
    type: string;
}

interface CalendarProps {
    calendarGrid: (Entry | null)[];
    onClick: (entry: Entry | null) => void;
}

const Calendar = ({ calendarGrid, onClick }: CalendarProps) => {
    return (
        <div className="calendar">
            <h2 className='calendar__title'>Novembro</h2>
            <div className="calendar__grid">
                {calendarGrid.map((entry, index) => (
                    <motion.div
                        layoutId={`item_${entry?.day}`}
                        key={index}
                        onClick={() => onClick(entry)}
                        className={`calendar__grid-item ${entry ? "filled " + entry.type : "empty"
                            }`}>

                        <div className="calendar__grid-item-header">
                            {entry ? (
                                <div className="calendar__grid-item-header-services">
                                    {entry.schedule.map((item: ColorItem, index: number) => (
                                        <div className='calendar__grid-item-header-services-balls'
                                            key={index}
                                            style={{
                                                backgroundColor: item.color
                                            }}
                                        ></div>
                                    ))}
                                </div>) : (<></>)}

                            <p className='calendar__grid-item-header-dayOfWeek'>{entry ? entry.dayOfWeek : ""}</p>
                        </div>
                        <p className='calendar__grid-item-day'>{entry ? parseInt(entry.day.split("/")[0]) : ""}</p>
                        {entry ?
                            (<div className='calendar__grid-item-customers'><EventAvailable /> 8 clientes</div>) : (<></>)
                        }
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default Calendar;
