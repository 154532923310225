import { z } from "zod";

export const profileDataSchema = z.object({
    descricao: z.string().min(1, { message: "Descricao inválida." }),
    segmento: z.string().min(1, { message: "Segmento inválido." }),
    telefone: z
        .string()
        .min(1, { message: "Telefone inválido." })
        .regex(/^\+\d{2} \(\d{2}\) \d{5}-\d{4}$/, "Formato inválido. Use +55 (XX) XXXXX-XXXX")
        .optional()
        .or(z.literal(""))
        .default(""),
    cep: z.string().min(1, { message: "CEP inválido." }),
    logradouro: z.string().min(1, { message: "Campo inválido." }),
    numero: z.string().min(1, { message: "Campo inválido." }),
    complemento: z.string(),
    bairro: z.string().min(1, { message: "Campo inválido." }),
    cidade: z.string().min(1, { message: "Campo inválido." }),
});

export type ProfileDataSchema = z.infer<typeof profileDataSchema>;