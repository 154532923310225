import { useState, useEffect } from 'react';
import LoginIconButton from '../../components/login/icon_button';
import ModalLoading from '../../components/core/modal_loading';
import ErrorModal from '../../components/core/modal_error';
import '../../styles/login.scss'
import { IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import { useNavigate } from 'react-router-dom';
import { loginSchema, LoginSchema } from '../../schemas/login/login-schema';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';

import poolData from '../../config/cognitoConfig';

function Login() {
    useEffect(() => {
        document.body.style.backgroundColor = 'white';
        return () => {
            document.body.style.backgroundColor = '#F3F5F9';
        };
    }, []);

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginSchema>({
        resolver: zodResolver(loginSchema),
    });

    const onSubmit: SubmitHandler<
        LoginSchema
    > = async data => {
        setIsLoading(true);

        const authenticationDetails = new AuthenticationDetails({
            Username: data.email,
            Password: data.senha,
        });

        const userPool = new CognitoUserPool(poolData);
        const cognitoUser = new CognitoUser({
            Username: data.email,
            Pool: userPool,
        });

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                const idToken = result.getIdToken().getJwtToken();
                console.log('Login successful:', idToken);
                setIsLoading(false);
                navigate('/dashboard');
            },
            onFailure: (err) => {
                console.error('Login failed:', err);
                setErrorMessage(err.message || 'Login failed');
                setIsLoading(false);
            },
        });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const closeErrorModal = () => setErrorMessage('');

    return (
        <>
            {isLoading && <ModalLoading />}
            {errorMessage && <ErrorModal message={errorMessage} onClose={closeErrorModal} />}
            <div className='container'>
                <div className="login-container">
                    <div className="side">
                        <p className="login-logo">Meet.ups</p>
                        <img className='login-img' src={'/login.svg'} alt="Logo" />
                    </div>
                    <div className="side right-side">
                        <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                            <p className="login-title">Login</p>

                            <div className="login-inputs">

                                <div>
                                    <p className='login-label'>E-mail</p>
                                    <input
                                        className={`input-text ${errors.email ? 'error' : ''}`}
                                        type="email"
                                        placeholder="Entrar com e-mail fornecido"
                                        {...register("email")} />
                                    {errors.email && (
                                        <p className="input-label-error">
                                            {errors.email.message}
                                        </p>
                                    )}
                                </div>

                                <div className="password-input-container">
                                    <p className='login-label'>Senha</p>
                                    <input
                                        className={`input-text ${errors.senha ? 'error' : ''}`}
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Digitar nova senha"
                                        {...register("senha")} />
                                    {errors.senha && (
                                        <p className="input-label-error">
                                            {errors.senha.message}
                                        </p>
                                    )}
                                    <IconButton onClick={togglePasswordVisibility} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </div>

                                <div className="login-forgot-pass">
                                    <a href="/login/get-code-password">Esqueci minha senha</a>
                                </div>

                            </div>
                            <div className='login-buttons'>
                                <button className='primary-button' type='submit'>
                                    Entrar
                                </button>
                                <div className="separator">ou</div>
                                <LoginIconButton text="Entrar com Google" icon={<img src={'/google.svg'} alt="Google" />} />
                                {/* <LoginIconButton text="Entrar com Facebook" icon={<img src={'/facebook.svg'} alt="Facebook" />} />
                                <LoginIconButton text="Entrar com Apple" icon={<img src={'/apple.svg'} alt="Apple" />} /> */}
                            </div>
                            <div className="login-register">
                                Não tem uma conta? <a href="/login/register">Cadastre-se aqui.</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </>
    );
}

export default Login;
