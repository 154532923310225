import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './sidebar.scss';
import SpaceDashboard from '@mui/icons-material/SpaceDashboard';
import NoteAlt from '@mui/icons-material/NoteAlt';
import Sms from '@mui/icons-material/Sms';
import Group from '@mui/icons-material/Group';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import Person from '@mui/icons-material/Person';
import SpaceDashboardOut from '@mui/icons-material/SpaceDashboardOutlined';
import NoteAltOut from '@mui/icons-material/NoteAltOutlined';
import SmsOut from '@mui/icons-material/SmsOutlined';
import GroupOut from '@mui/icons-material/GroupOutlined';
import CalendarMonthOut from '@mui/icons-material/CalendarMonthOutlined';
import PersonOut from '@mui/icons-material/PersonOutline';
import { DashboardCustomize, DashboardCustomizeOutlined, Schedule, ScheduleOutlined } from '@mui/icons-material';


const sidebarNavItems = [
    {
        display: 'Dashboard',
        icon: <SpaceDashboardOut />,
        activeIcon: <SpaceDashboard />,
        to: '/dashboard',
        section: 'MEU ESPAÇO'
    },
    {
        display: 'Clientes',
        icon: <GroupOut />,
        activeIcon: <Group />,
        to: '/business/customers',
        section: 'MEU ESPAÇO'
    },
    {
        display: 'Agendamentos',
        icon: <CalendarMonthOut />,
        activeIcon: <CalendarMonth />,
        to: '/business/schedules',
        section: 'MEU ESPAÇO'
    },
    {
        display: 'Dados cadastrais',
        icon: <NoteAltOut />,
        activeIcon: <NoteAlt />,
        to: '/configuration/profile',
        section: 'CONFIGURAÇÕES'
    },
    {
        display: 'Mensagens',
        icon: <SmsOut />,
        activeIcon: <Sms />,
        to: '/configuration/messages',
        section: 'CONFIGURAÇÕES'
    },
    {
        display: 'Funcionarios',
        icon: <PersonOut />,
        activeIcon: <Person />,
        to: '/team/employees',
        section: 'EQUIPE'
    },
    {
        display: 'Serviços',
        icon: <DashboardCustomizeOutlined />,
        activeIcon: <DashboardCustomize />,
        to: '/team/services',
        section: 'EQUIPE'
    },
    {
        display: 'Horários e turnos',
        icon: <ScheduleOutlined />,
        activeIcon: <Schedule />,
        to: '/team/turns',
        section: 'EQUIPE'
    },
]

const Sidebar = () => {
    let prevSection = '';

    const [activeIndex, setActiveIndex] = useState(0);
    const [activePosition, setActivePosition] = useState(0);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const indicatorRef = useRef<HTMLDivElement>(null);
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            if (sidebarRef.current && indicatorRef.current) {
                const sidebarItem = sidebarRef.current.querySelector('.sidebar__menu__item');
                indicatorRef.current.style.height = `${sidebarItem?.clientHeight}px`;
            }
        }, 50);
    }, []);

    useEffect(() => {
        const curPath = window.location.pathname;
        const activeItem = sidebarNavItems.findIndex(item => item.to === curPath);
        setActiveIndex(curPath.length === 0 ? 0 : activeItem);
        setTimeout(() => {
            const sidebarItem = sidebarRef.current?.querySelector('.sidebar__menu__item.active') as HTMLElement;
            var positionY = sidebarItem.offsetTop;
            setActivePosition(positionY);
        }, 50);
    }, [location]);

    return <div className='sidebar'>
        <div className="sidebar__logo">
            <p>Meet.ups</p>
        </div>
        <div ref={sidebarRef} className="sidebar__menu">
            <div
                ref={indicatorRef}
                className="sidebar__menu__indicator"
                style={{
                    position: 'absolute',
                    top: `${activePosition}px`,
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}
            ></div>
            {
                sidebarNavItems.map((item, index) => {
                    let showSection = false;

                    if (prevSection !== item.section) {
                        prevSection = item.section;
                        showSection = true;
                    }

                    return (
                        <div>
                            {showSection && <div className='sidebar__menu__section'>{item.section}</div>}
                            <Link to={item.to} key={index}>
                                <div className={`sidebar__menu__item ${activeIndex === index ? 'active' : ''}`}>
                                    <div className="sidebar__menu__item__icon">
                                        {activeIndex === index ? item.activeIcon : item.icon}
                                    </div>
                                    <div className="sidebar__menu__item__text">
                                        {item.display}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    );
                })
            }
        </div>
    </div>;
};

export default Sidebar;