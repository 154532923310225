import { useEffect, useState } from 'react';
import '../../styles/login.scss';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { confirmCodeSchema, ConfirmCodeSchema } from '../../schemas/login/confirm-code-schema';
import { CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import poolData from '../../config/cognitoConfig';
import ErrorModal from '../../components/core/modal_error';
import { useLocation } from 'react-router-dom';


function ConfirmRegistration() {
    const location = useLocation();
    const email = location.state?.email;

    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [countdown, setCountdown] = useState(60);

    useEffect(() => {
        document.body.style.backgroundColor = 'white';

        let timer;

        if (isResendDisabled) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown <= 1) {
                        clearInterval(timer);
                        setIsResendDisabled(false);
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
        }

        return () => {
            clearInterval(timer);
            document.body.style.backgroundColor = '#F3F5F9';
        };
    }, [isResendDisabled]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ConfirmCodeSchema>({
        resolver: zodResolver(confirmCodeSchema),
    });

    const onSubmit: SubmitHandler<
        ConfirmCodeSchema
    > = async data => {
        const userPool = new CognitoUserPool(poolData);
        const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });

        if (!cognitoUser) {
            setErrorMessage('No user found for verification. Please register first.');
            return;
        }

        const verificationCode = data.num1.toString() + data.num2.toString() + data.num3.toString() + data.num4.toString() + data.num5.toString() + data.num6.toString();

        cognitoUser.confirmRegistration(verificationCode, true, (err) => {
            if (err) {
                setErrorMessage(err.message || 'Verification failed.');
                return;
            }

            navigate('/login');
        });

    };

    const resendCode = () => {
        setErrorMessage('');
        setIsResendDisabled(true);
        setCountdown(60);

        const userPool = new CognitoUserPool(poolData);
        const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });

        cognitoUser.resendConfirmationCode((err) => {
            if (err) {
                setErrorMessage(err.message || 'Failed to resend the code.');
                return;
            }
        });
    };

    const closeErrorModal = () => setErrorMessage('');

    return (
        <>
            {errorMessage && <ErrorModal message={errorMessage} onClose={closeErrorModal} />}
            <div className='container'>
                <div className="login-container">
                    <div className="side">
                        <p className="login-logo">Meet.ups</p>
                        <img className='login-img' src="/password.svg" alt="Logo" />
                    </div>
                    <div className="side right-side">
                        <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                            <p className="login-title">Verique seu email</p>

                            <div className="login-inputs">

                                <div>
                                    <p className='login-label'>Enviamos um e-mail para {email} com o
                                        código de verificação. Verifique sua caixa de entrada,
                                        copie o código e insira-o abaixo.</p>
                                    <div className="login-confirm-fields">
                                        <input
                                            className={`input-text ${errors.num1 ? 'error' : ''}`}
                                            type="text"
                                            maxLength={1}
                                            style={{ padding: '10px 4px', textAlign: 'center', width: '38px', height: '48px' }}
                                            {...register("num1")} />
                                        <input
                                            className={`input-text ${errors.num2 ? 'error' : ''}`}
                                            type="text"
                                            maxLength={1}
                                            style={{ padding: '10px 4px', textAlign: 'center', width: '38px', height: '48px' }}
                                            {...register("num2")} />
                                        <input
                                            className={`input-text ${errors.num3 ? 'error' : ''}`}
                                            type="text"
                                            maxLength={1}
                                            style={{ padding: '10px 4px', textAlign: 'center', width: '38px', height: '48px' }}
                                            {...register("num3")} />
                                        <input
                                            className={`input-text ${errors.num4 ? 'error' : ''}`}
                                            type="text"
                                            maxLength={1}
                                            style={{ padding: '10px 4px', textAlign: 'center', width: '38px', height: '48px' }}
                                            {...register("num4")} />
                                        <input
                                            className={`input-text ${errors.num5 ? 'error' : ''}`}
                                            type="text"
                                            maxLength={1}
                                            style={{ padding: '10px 4px', textAlign: 'center', width: '38px', height: '48px' }}
                                            {...register("num5")} />
                                        <input
                                            className={`input-text ${errors.num6 ? 'error' : ''}`}
                                            type="text"
                                            maxLength={1}
                                            style={{ padding: '10px 4px', textAlign: 'center', width: '38px', height: '48px' }}
                                            {...register("num6")} />
                                    </div>
                                    {/* Mensagens de erro específicas */}
                                    {errors.num1?.message && <p className="input-label-error">{errors.num1?.message}</p>}
                                    {errors.num2?.message && <p className="input-label-error">{errors.num2?.message}</p>}
                                    {errors.num3?.message && <p className="input-label-error">{errors.num3?.message}</p>}
                                    {errors.num4?.message && <p className="input-label-error">{errors.num4?.message}</p>}
                                    {errors.num5?.message && <p className="input-label-error">{errors.num5?.message}</p>}
                                    {errors.num6?.message && <p className="input-label-error">{errors.num6?.message}</p>}
                                </div>

                                <div className='login-buttons'>
                                    <button className='primary-button' onClick={resendCode} disabled={isResendDisabled}>
                                        {isResendDisabled ? `O codigo expira em (${countdown} segundos)` : 'Reenviar codigo'}
                                    </button>
                                    <button className='primary-button' type='submit'>
                                        Validar
                                    </button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

    );
}

export default ConfirmRegistration;
