import { z } from "zod";

export const getCodeSchema = z.object({
    email: z.string()
        .email("Formato de e-mail inválido.")
        .optional()
        .or(z.literal(""))
        .default(""),
    celular: z
        .string()
        .regex(/^\+\d{2} \(\d{2}\) \d{5}-\d{4}$/, "Formato inválido. Use +55 (XX) XXXXX-XXXX")
        .optional()
        .or(z.literal(""))
        .default(""),
}).refine(
    (data) => data.celular.trim() !== "" || data.email.trim() !== "",
    {
        message: "Pelo menos um dos campos (email ou celular) deve ser preenchido.",
        path: ["email"],
    }
);

export type GetCodeSchema = z.infer<typeof getCodeSchema>;