import { Outlet } from "react-router-dom";
import Sidebar from "../sidebar/sidebar";
import UserInfo from "../user_info/UserInfo";
import './app_layout.scss';

const AppLayout = () => {
    return <div style={{
        padding: '36px 24px 0px 370px'
    }}>
        <Sidebar />
        <UserInfo />
        <div className="app-layout__container">
            <div className="app-layout__outlet">
                <Outlet />
            </div>
        </div>
    </div>;
};

export default AppLayout;