import './icon_button.scss'

interface LoginIconButtonProps { text: string; icon: JSX.Element }

const LoginIconButton: React.FC<LoginIconButtonProps> = ({ text, icon }) => {
    return (
        <button className="icon-button" type='submit'>
            <span className="icon-button__icon">{icon}</span>
            <span className="icon-button__text">{text}</span>
        </button>
    );
};

export default LoginIconButton;
