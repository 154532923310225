import './modal_error.scss';
import { motion } from 'framer-motion';

const ErrorModal = ({ onClose, message }) => {
    return (
        <motion.div
            className="modal-overlay-error"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={onClose}
        >
            <motion.div
                className="modal-content-error"
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.8 }}
                transition={{ duration: 0.3 }}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="error-icon">⚠️</div>
                <p>{message}</p>
                <button className="close-button" onClick={onClose}>Close</button>
            </motion.div>
        </motion.div>
    );
};

export default ErrorModal;
