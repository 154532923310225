import { z } from "zod";

export const confirmCodeSchema = z.object({
    num1: z.string().max(1).transform((val) => parseFloat(val)),
    num2: z.string().max(1).transform((val) => parseFloat(val)),
    num3: z.string().max(1).transform((val) => parseFloat(val)),
    num4: z.string().max(1).transform((val) => parseFloat(val)),
    num5: z.string().max(1).transform((val) => parseFloat(val)),
    num6: z.string().max(1).transform((val) => parseFloat(val)),
})
    .superRefine((data, ctx) => {
        const keys = ['num1', 'num2', 'num3', 'num4', 'num5', 'num6'] as const;

        keys.forEach((key) => {
            const value = data[key];
            if (isNaN(value)) {
                ctx.addIssue({
                    path: [key],
                    message: `O valor de ${key} deve ser um número válido`,
                    code: z.ZodIssueCode.custom,
                });
            }
        });
    });

export type ConfirmCodeSchema = z.infer<typeof confirmCodeSchema>;