import { useEffect } from 'react';
import '../../styles/login.scss';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { confirmCodeSchema, ConfirmCodeSchema } from '../../schemas/login/confirm-code-schema';

function ConfirmCodePassword() {
    useEffect(() => {
        document.body.style.backgroundColor = 'white';
        return () => {
            document.body.style.backgroundColor = '#F3F5F9';
        };
    }, []);

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ConfirmCodeSchema>({
        resolver: zodResolver(confirmCodeSchema),
    });

    const onSubmit: SubmitHandler<
        ConfirmCodeSchema
    > = async data => {
        navigate('/login/new-password');
    };

    return (
        <div className='container'>
            <div className="login-container">
                <div className="side">
                    <p className="login-logo">Meet.ups</p>
                    <img className='login-img' src="/password.svg" alt="Logo" />
                </div>
                <div className="side right-side">
                    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                        <p className="login-title">Esqueci a senha</p>

                        <div className="login-inputs">

                            <div>
                                <p className='login-label'>Digite o código recebido</p>
                                <div className="login-confirm-fields">
                                    <input
                                        className={`input-text ${errors.num1 ? 'error' : ''}`}
                                        type="text"
                                        maxLength={1}
                                        style={{padding: '10px 4px', textAlign: 'center', width: '38px', height: '48px'}}
                                        {...register("num1")} />
                                    <input
                                        className={`input-text ${errors.num2 ? 'error' : ''}`}
                                        type="text"
                                        maxLength={1}
                                        style={{padding: '10px 4px', textAlign: 'center', width: '38px', height: '48px'}}
                                        {...register("num2")} />
                                    <input
                                        className={`input-text ${errors.num3 ? 'error' : ''}`}
                                        type="text"
                                        maxLength={1}
                                        style={{padding: '10px 4px', textAlign: 'center', width: '38px', height: '48px'}}
                                        {...register("num3")} />
                                    <input
                                        className={`input-text ${errors.num4 ? 'error' : ''}`}
                                        type="text"
                                        maxLength={1}
                                        style={{padding: '10px 4px', textAlign: 'center', width: '38px', height: '48px'}}
                                        {...register("num4")} />
                                    <input
                                        className={`input-text ${errors.num5 ? 'error' : ''}`}
                                        type="text"
                                        maxLength={1}
                                        style={{padding: '10px 4px', textAlign: 'center', width: '38px', height: '48px'}}
                                        {...register("num5")} />
                                    <input
                                        className={`input-text ${errors.num6 ? 'error' : ''}`}
                                        type="text"
                                        maxLength={1}
                                        style={{padding: '10px 4px', textAlign: 'center', width: '38px', height: '48px'}}
                                        {...register("num6")} />
                                </div>
                                {/* Mensagens de erro específicas */}
                                {errors.num1?.message && <p className="input-label-error">{errors.num1?.message}</p>}
                                {errors.num2?.message && <p className="input-label-error">{errors.num2?.message}</p>}
                                {errors.num3?.message && <p className="input-label-error">{errors.num3?.message}</p>}
                                {errors.num4?.message && <p className="input-label-error">{errors.num4?.message}</p>}
                                {errors.num5?.message && <p className="input-label-error">{errors.num5?.message}</p>}
                                {errors.num6?.message && <p className="input-label-error">{errors.num6?.message}</p>}

                                <p className='login-sub-label'>O codigo expira em 60 segundos</p>
                            </div>

                            <div className='login-buttons'>
                                <button className='primary-button' type='submit'>
                                    Confirmar
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ConfirmCodePassword;
