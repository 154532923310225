import { useEffect, useState } from 'react';
import '../../styles/login.scss';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { newPasswordSchema, NewPasswordSchema } from '../../schemas/login/new-password-schema';
import { IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';


function NewPassword() {
    useEffect(() => {
        document.body.style.backgroundColor = 'white';
        return () => {
            document.body.style.backgroundColor = '#F3F5F9';
        };
    }, []);

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<NewPasswordSchema>({
        resolver: zodResolver(newPasswordSchema),
    });

    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onSubmit: SubmitHandler<
        NewPasswordSchema
    > = async data => {
        navigate('/login');
    };

    return (
        <div className='container'>
            <div className="login-container">
                <div className="side">
                    <p className="login-logo">Meet.ups</p>
                    <img className='login-img' src="/password.svg" alt="Logo" />
                </div>
                <div className="side right-side">
                    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                        <p className="login-title">Nova senha</p>

                        <div className="login-inputs">

                            <div className="password-input-container">
                                <p className='login-label'>Cadastrar nova senha</p>
                                <input
                                    className={`input-text ${errors.senha ? 'error' : ''}`}
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Digitar nova senha"
                                    {...register("senha")} />
                                {errors.senha && (
                                    <p className="input-label-error">
                                        {errors.senha.message}
                                    </p>
                                )}
                                <IconButton onClick={togglePasswordVisibility} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </div>

                            <div className="password-input-container">
                                <p className='login-label'>Confirmar nova senha</p>
                                <input
                                    className={`input-text ${errors.confirmSenha ? 'error' : ''}`}
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Digitar nova senha"
                                    {...register("confirmSenha")} />
                                {errors.confirmSenha && (
                                    <p className="input-label-error">
                                        {errors.confirmSenha.message}
                                    </p>
                                )}
                                <IconButton onClick={togglePasswordVisibility} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </div>

                            <div className='login-buttons'>
                                <button className='primary-button' type='submit'>
                                    Cadastrar nova senha
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default NewPassword;
