import React, { useEffect, useState } from 'react';
import '../styles/schedules.scss';
import Calendar from '../components/schedules/calendar';
import ModalDay from '../components/schedules/modal_day';


type Entry = {
    day: string;
    day_of_week: string;
    type: string;
    schedule: ColorItem[];
};

interface ColorItem {
    color: string;
}


const SchedulesPage = () => {
    const [calendarData, setCalendarData] = useState<Entry[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedDay, setSelectedDay] = useState(null);

    const mockCalendarData: Entry[] = [
        { day: "01/11/2024", day_of_week: "Sex", type: "past", schedule: [] },
        { day: "02/11/2024", day_of_week: "Sáb", type: "past", schedule: [] },
        { day: "03/11/2024", day_of_week: "Dom", type: "past", schedule: [] },
        { day: "04/11/2024", day_of_week: "Seg", type: "past", schedule: [] },
        { day: "05/11/2024", day_of_week: "Ter", type: "past", schedule: [] },
        { day: "06/11/2024", day_of_week: "Qua", type: "past", schedule: [] },
        { day: "07/11/2024", day_of_week: "Qui", type: "past", schedule: [] },
        { day: "08/11/2024", day_of_week: "Sex", type: "past", schedule: [] },
        { day: "09/11/2024", day_of_week: "Sáb", type: "past", schedule: [] },
        { day: "10/11/2024", day_of_week: "Dom", type: "past", schedule: [] },
        { day: "11/11/2024", day_of_week: "Seg", type: "past", schedule: [] },
        { day: "12/11/2024", day_of_week: "Ter", type: "past", schedule: [] },
        { day: "13/11/2024", day_of_week: "Qua", type: "past", schedule: [] },
        { day: "14/11/2024", day_of_week: "Qui", type: "past", schedule: [] },
        { day: "15/11/2024", day_of_week: "Sex", type: "past", schedule: [] },
        { day: "16/11/2024", day_of_week: "Sáb", type: "past", schedule: [] },
        { day: "17/11/2024", day_of_week: "Dom", type: "past", schedule: [] },
        { day: "18/11/2024", day_of_week: "Seg", type: "past", schedule: [] },
        { day: "19/11/2024", day_of_week: "Ter", type: "past", schedule: [] },
        { day: "20/11/2024", day_of_week: "Qua", type: "past", schedule: [] },
        {
            day: "21/11/2024", day_of_week: "Qui", type: "today", schedule: [
                { color: "red" },
                { color: "red" },
                { color: "green" },
                { color: "red" },
                { color: "green" },
                { color: "green" },
                { color: "green" },
                { color: "green" }
            ]
        },
        { day: "22/11/2024", day_of_week: "Sex", type: "future", schedule: [] },
        { day: "23/11/2024", day_of_week: "Sáb", type: "future", schedule: [] },
        { day: "24/11/2024", day_of_week: "Dom", type: "future", schedule: [] },
        { day: "25/11/2024", day_of_week: "Seg", type: "future", schedule: [] },
        { day: "26/11/2024", day_of_week: "Ter", type: "future", schedule: [] },
        { day: "27/11/2024", day_of_week: "Qua", type: "future", schedule: [] },
        { day: "28/11/2024", day_of_week: "Qui", type: "future", schedule: [] },
        { day: "29/11/2024", day_of_week: "Sex", type: "future", schedule: [] },
        { day: "30/11/2024", day_of_week: "Sáb", type: "future", schedule: [] }
    ];


    useEffect(() => {
        setTimeout(() => {
            setCalendarData(mockCalendarData);
            setLoading(false);
        }, 1000);
    }, []);

    const buildCalendarGrid = () => {
        const grid = Array(42).fill(null);

        calendarData.forEach((entry) => {
            const date = new Date(entry.day.split("/").reverse().join("-") + "T00:00:00");
            const dayOfMonth = date.getDate();

            const firstDay = new Date(
                date.getFullYear(),
                date.getMonth(),
                1
            ).getDay();

            const position = firstDay + dayOfMonth - 1;

            grid[position] = { day: entry.day, dayOfWeek: entry.day_of_week, type: entry.type, schedule: entry.schedule };
        });

        return grid;
    };

    const calendarGrid = buildCalendarGrid();

    const handleClick = (entry: any) => {
        setSelectedDay(entry);
        console.log('Item clicado:', entry);
    };

    return (
        <div className="schedules">
            {loading ? (
                <p className='schedules__title'>Carregando...</p>
            ) : (
                <>
                    {selectedDay ? <ModalDay entry={selectedDay} /> : <Calendar calendarGrid={calendarGrid} onClick={handleClick} />}
                </>
            )}
        </div>
    );
};

export default SchedulesPage;