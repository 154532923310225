import { z } from "zod";

const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;

export const registerSchema = z.object({
    email: z.string().email("Formato de e-mail inválido."),
    nome: z.string().min(1, { message: "Nome inválida." }),
    celular: z
        .string()
        .regex(/^\+\d{2} \(\d{2}\) \d{5}-\d{4}$/, "Formato inválido. Use +55 (XX) XXXXX-XXXX"),
    senha: z.string().regex(passwordRegex, { message: "A senha deve ter pelo menos 8 caracteres, 1 maiúsculo e 1 caractere especial.", }),
    confirmSenha: z.string().regex(passwordRegex, { message: "A senha deve ter pelo menos 8 caracteres, 1 maiúsculo e 1 caractere especial.", }),
}).refine((data) => data.senha === data.confirmSenha, {
    message: "As senhas devem ser iguais.",
    path: ["confirmSenha"], // Aponta o erro especificamente para o campo `confirmSenha`
});

export type RegisterSchema = z.infer<typeof registerSchema>;