import { profileDataSchema, ProfileDataSchema } from '../schemas/profile/profile-data-schema';
import DescriptionField from '../components/profile/description_field';
import '../styles/profile.scss';
import { LocationOnOutlined, PhoneEnabledOutlined, Storefront } from '@mui/icons-material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import InputMask from 'react-input-mask';
import { useState } from 'react';

const ProfilePage = () => {

    const [showAddress, setShowAddress] = useState(false);

    const {
        register,
        handleSubmit,
        trigger,
        formState: { errors },
    } = useForm<ProfileDataSchema>({
        resolver: zodResolver(profileDataSchema),
    });

    const onSubmit: SubmitHandler<
        ProfileDataSchema
    > = async data => {

    };

    const handleCEP = async () => {
        const isValid = await trigger("cep");
        if (isValid) {
            setShowAddress(true);
        } else {
            setShowAddress(false);
        }
    };

    return (
        <div className="profile">
            <p className="profile__title">Mantenha seus dados sempre atualizados, essas informações estarão disponíveis no seu site!</p>

            <div className="profile__description">
                <DescriptionField
                    placeholder="Descrição do seu Negócio..."
                    value={""}
                    onChange={(e) => { }} />
            </div>

            <form className="profile__form" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-4">
                        <div className="profile__field">
                            <span className="profile__field_icone"><Storefront /></span>
                            <p className="profile__field_label">Segmento de atuação:</p>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="profile__field_input">
                            <input
                                className={`input-text ${errors.segmento ? 'error' : ''}`}
                                type={'text'}
                                placeholder="Segmento"
                                {...register("segmento")} />
                            {errors.segmento && (
                                <p className="input-label-error">
                                    {errors.segmento.message}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className="profile__field">
                            <span className="profile__field_icone"><PhoneEnabledOutlined /></span>
                            <p className="profile__field_label">Telefone:</p>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="profile__field_input">
                            <InputMask
                                className={`input-text ${errors.telefone ? 'error' : ''}`}
                                mask={"+55 (99) 99999-9999"}
                                maskChar={null}
                                {...register("telefone")} />
                            {errors.telefone && (
                                <p className="input-label-error">
                                    {errors.telefone.message}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className="profile__field">
                            <span className="profile__field_icone"><LocationOnOutlined /></span>
                            <p className="profile__field_label">Endereço Comercial:</p>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="profile__field_input">
                            <InputMask
                                className={`input-text ${errors.cep ? 'error' : ''}`}
                                mask={"99999-999"}
                                maskChar={null}
                                {...register("cep")} />
                            {errors.cep && (
                                <p className="input-label-error">
                                    {errors.cep.message}
                                </p>
                            )}
                            <div className="search-button">
                                <button className='secundary-button' onClick={handleCEP}>
                                    Pesquisar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    showAddress ? (<div className="profile__form-address">
                        <div className="row">
                            <div className="col-6">
                                <p className='login-label'>Logradouro</p>
                                <input
                                    className={`input-text ${errors.logradouro ? 'error' : ''}`}
                                    type="text"
                                    {...register("logradouro")} />
                                {errors.logradouro && (
                                    <p className="input-label-error">
                                        {errors.logradouro.message}
                                    </p>
                                )}
                            </div>
                            <div className='col-6'>
                                <p className='login-label'>Número</p>
                                <input
                                    className={`input-text ${errors.numero ? 'error' : ''}`}
                                    type="text"
                                    {...register("numero")} />
                                {errors.numero && (
                                    <p className="input-label-error">
                                        {errors.numero.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-6'>
                                <p className='login-label'>Cidade</p>
                                <input
                                    className={`input-text ${errors.cidade ? 'error' : ''}`}
                                    type="text"
                                    {...register("cidade")} />
                                {errors.cidade && (
                                    <p className="input-label-error">
                                        {errors.cidade.message}
                                    </p>
                                )}
                            </div>
                            <div className='col-6'>
                                <p className='login-label'>Bairro</p>
                                <input
                                    className={`input-text ${errors.bairro ? 'error' : ''}`}
                                    type="text"
                                    {...register("bairro")} />
                                {errors.bairro && (
                                    <p className="input-label-error">
                                        {errors.bairro.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-12'>
                                <p className='login-label'>Complemento</p>
                                <input
                                    className={`input-text ${errors.complemento ? 'error' : ''}`}
                                    type="text"
                                    {...register("complemento")} />
                                {errors.complemento && (
                                    <p className="input-label-error">
                                        {errors.complemento.message}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="row row-submit">
                    <div className="col-9"></div>
                    <div className="col-3">
                        <button className='terciary-button' type='submit'>
                            Cadastrar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ProfilePage;