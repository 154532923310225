import { EventAvailable } from '@mui/icons-material';
import './modal_day.scss';
import { motion } from 'framer-motion';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { ShedulesTableSchema } from '@/schemas/schedules/schedules-table';
import React from 'react';
import CustomTable from '../core/custom_table';

interface ColorItem {
    color: string;
}

interface Entry {
    day: string;
    dayOfWeek: string;
    schedule: ColorItem[];
    type: string;
}

interface EntryProps {
    entry: (Entry | null);
}

const columnHelper = createColumnHelper<ShedulesTableSchema>();

const Calendar = ({ entry }: EntryProps) => {

    const columns = [
        columnHelper.accessor('horario', {
            header: 'Horário',
            footer: props => props.column.id,
            cell: info => {
                const hora = info.row.original.horario;
                return (
                    <div className="cell-agenda">
                        <EventAvailable className='cell-agenda__icon' />
                        <div className="cell-agenda__texto">{hora}</div>
                    </div>
                );
            },
        }),
        columnHelper.accessor('nome', {
            header: 'Nome',
            footer: props => props.column.id,
        }),
        columnHelper.accessor('telefone', {
            header: 'Telefone',
            footer: props => props.column.id,
        }),
        columnHelper.accessor('profissional', {
            header: 'Profissional',
            footer: props => props.column.id,
            cell: info => {
                const nome = info.row.original.profissional;
                const foto = info.row.original.profissional_foto;

                return (
                    <div className="cell-nome">
                        <img className="cell-nome__foto" src={foto} alt="foto" />
                        <div className="cell-nome__texto">{nome}</div>
                    </div>
                );
            },
        }),
        columnHelper.accessor('servico', {
            header: 'Serviço',
            footer: props => props.column.id,
            cell: info => {
                const servico = info.row.original.servico;

                return (
                    <div className="cell-agenda">
                        <div className="cell-agenda__texto">{servico}</div>
                    </div>
                );
            },
        }),
    ];



    const data = React.useMemo(
        () => [
            { id: 1, horario: '13:00', nome: 'Maria Fernanda Tarchi', telefone: '(11) 98765-4321', profissional: 'Guilherme Carvalho Bao', profissional_foto: 'https://i.pravatar.cc/150?img=50', servico: 'Limpeza' },
            { id: 2, horario: '14:30', nome: 'José Carlos da Silveira', telefone: '(31) 93249-1111', profissional: 'Guilherme Carvalho Bao', profissional_foto: 'https://i.pravatar.cc/150?img=50', servico: 'Extração' },
            { id: 3, horario: '15:30', nome: 'Guilherme Bao', telefone: '(31) 99999-2222', profissional: 'Guilherme Carvalho Bao', profissional_foto: 'https://i.pravatar.cc/150?img=50', servico: 'Cirurgia' },
            { id: 4, horario: '16:30', nome: 'Antônio da Silva Nunes', telefone: '(31) 93249-3333', profissional: 'Anna Carolina Xavier Moura', profissional_foto: 'https://i.pravatar.cc/150?img=50', servico: 'Limpeza' },
            { id: 5, horario: '17:30', nome: 'Laura Mendonça', telefone: '(31) 87465-2222', profissional: 'Anna Carolina Xavier Moura', profissional_foto: 'https://i.pravatar.cc/150?img=50', servico: 'Limpeza' },
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="modal_day">
            <motion.div
                layoutId={`item_${entry?.day}`}
                className={`calendar__grid-item-opened ${entry ? "filled " + entry.type : "empty"
                    }`}>

                <div className="calendar__grid-item-opened-header">
                    {entry ? (<div className="calendar__grid-item-opened-header-services">
                        {entry.schedule.map((item: ColorItem, index: number) => (
                            <div className='calendar__grid-item-opened-header-services-balls'
                                key={index}
                                style={{
                                    backgroundColor: item.color
                                }}
                            ></div>
                        ))}
                    </div>) : (<></>)}

                    <p className='calendar__grid-item-opened-header-dayOfWeek'>{entry ? entry.dayOfWeek : ""}</p>
                </div>
                <p className='calendar__grid-item-opened-day'>{entry ? parseInt(entry.day.split("/")[0]) : ""}</p>
                {entry ?
                    (
                        <div className="calendar__grid-item-opened-customers">
                            <div className='calendar__grid-item-opened-customers-tag'><EventAvailable /> 8 clientes</div>
                        </div>

                    ) : (<></>)
                }
                <div className="modal_day__table">
                    <CustomTable table={table} />
                </div>
            </motion.div>
        </div >
    );
};

export default Calendar;
