import './App.css';
import Login from './pages/login/login';
import GetCodePassword from './pages/login/get-code-password';
import Blank from './pages/blank';
import DashboardPage from './pages/dashboard';
import ProfilePage from './pages/profile';
import AppLayout from './components/layout/AppLayout';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MessagesPage from './pages/messages';
import ConfirmCodePassword from './pages/login/confirm-code-password';
import NewPassword from './pages/login/new-password';
import CustomersPage from './pages/customers';
import SchedulesPage from './pages/schedules';
import Register from './pages/login/register';
import ConfirmRegistration from './pages/login/confirm-registration';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route index path="/login" element={<Login />} />
        <Route index path="/login/get-code-password" element={<GetCodePassword />} />
        <Route index path="/login/confirm-code-password" element={<ConfirmCodePassword />} />
        <Route index path="/login/new-password" element={<NewPassword />} />
        <Route index path="/login/register" element={<Register />} />
        <Route index path="/login/confirm-registration" element={<ConfirmRegistration />} />

        <Route path='/' element={<AppLayout />}>
          <Route path='/dashboard' element={<DashboardPage />} />
          <Route path='/business/customers' element={<CustomersPage />} />
          <Route path='/business/schedules' element={<SchedulesPage />} />

          <Route path='/configuration/profile' element={<ProfilePage />} />
          <Route path='/configuration/messages' element={<MessagesPage />} />

          <Route path='/team/employees' element={<Blank />} />
          <Route path='/team/services' element={<Blank />} />
          <Route path='/team/turns' element={<Blank />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
