import '../styles/customers.scss'

import React from "react";
import {
    useReactTable,
    createColumnHelper,
    getCoreRowModel,
} from '@tanstack/react-table';
import { CustomersTableSchema } from "@/schemas/customers/customers-table";
import { EventAvailable } from '@mui/icons-material';
import CustomTable from '../components/core/custom_table';

enum ViewState {
    INITIAL,
    LOADING,
    ERROR,
    SUCCESS,
}

const columnHelper = createColumnHelper<CustomersTableSchema>();

const CustomersPage = () => {

    const columns = [
        columnHelper.accessor('nome', {
            header: 'Nome',
            footer: props => props.column.id,
            cell: info => {
                const nome = info.row.original.nome;
                const foto = info.row.original.foto;

                return (
                    <div className="cell-nome">
                        <img className="cell-nome__foto" src={foto} alt="foto" />
                        <div className="cell-nome__texto">{nome}</div>
                    </div>
                );
            },
        }),
        columnHelper.accessor('telefone', {
            header: 'Telefone',
            footer: props => props.column.id,
        }),
        columnHelper.accessor('email', {
            header: 'E-mail',
            footer: props => props.column.id,
        }),
        columnHelper.accessor('id', {
            header: 'Agenda',
            footer: props => props.column.id,
            cell: info => {
                // const id = info.row.original.id;

                return (
                    <div className="cell-agenda">
                        <EventAvailable className='cell-agenda__icon' />
                        <div className="cell-agenda__texto">Ver agenda</div>
                    </div>
                );
            },
        }),
    ];

    const data = React.useMemo(
        () => [
            { id: 1, nome: 'José Carlos da Silveira ', foto: 'https://i.pravatar.cc/150?img=50', telefone: '(11) 98765-4321', email: 'joao@email.com' },
            { id: 2, nome: 'Maria Fernanda Tarchi', foto: 'https://i.pravatar.cc/150?img=50', telefone: '(21) 91234-5678', email: 'maria@email.com' },
            { id: 3, nome: 'Pedro Antonio Gomes', foto: 'https://i.pravatar.cc/150?img=50', telefone: '(31) 99876-5432', email: 'pedro@email.com' },
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="customers">
            <CustomTable table={table} />
        </div>
    );
};

export default CustomersPage;