import { useEffect, useState } from 'react';
import '../../styles/login.scss';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { registerSchema, RegisterSchema } from '../../schemas/login/register-schema';
import { IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import LoginIconButton from '../../components/login/icon_button';
import InputMask from 'react-input-mask';
import { CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import poolData from '../../config/cognitoConfig';
import ErrorModal from '../../components/core/modal_error';

function Register() {
    useEffect(() => {
        document.body.style.backgroundColor = 'white';
        return () => {
            document.body.style.backgroundColor = '#F3F5F9';
        };
    }, []);

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RegisterSchema>({
        resolver: zodResolver(registerSchema),
    });

    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onSubmit: SubmitHandler<
        RegisterSchema
    > = async data => {

        const phone = '+' + data.celular.replace(/\D/g, '');

        const userPool = new CognitoUserPool(poolData);
        const attributeList = [
            new CognitoUserAttribute({ Name: 'email', Value: data.email }),
            new CognitoUserAttribute({ Name: 'phone_number', Value: phone }),
            new CognitoUserAttribute({ Name: 'name', Value: data.nome }),
        ];

        userPool.signUp(data.email, data.senha, attributeList, attributeList, (err, result) => {
            if (err) {
                setErrorMessage(err.message || 'An error occurred during signup.');
                return;
            }

            navigate('/login/confirm-registration', { state: { email: data.email } });
        });
    };

    const closeErrorModal = () => setErrorMessage('');

    return (
        <>
            {errorMessage && <ErrorModal message={errorMessage} onClose={closeErrorModal} />}
            <div className='container'>
                <div className="login-container">
                    <div className="side">
                        <p className="login-logo">Meet.ups</p>
                        <img className='login-img' src="/password.svg" alt="Logo" />
                    </div>
                    <div className="side right-side">
                        <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                            <p className="login-title">Criar conta</p>
                            <p className="login-subtitle">Registre-se na Meetups utilizando seu e-mail ou sua
                                conta Google e aproveite a plataforma gratuitamente.</p>

                            <LoginIconButton text="Criar conta com Google" icon={<img src={'/google.svg'} alt="Google" />} />

                            <div className="login-inputs">

                                <div>
                                    <p className='login-label'>E-mail</p>
                                    <input
                                        className={`input-text ${errors.email ? 'error' : ''}`}
                                        type="email"
                                        placeholder="Entrar com e-mail fornecido"
                                        {...register("email")} />
                                    {errors.email && (
                                        <p className="input-label-error">
                                            {errors.email.message}
                                        </p>
                                    )}
                                </div>

                                <div>
                                    <p className='login-label'>Nome</p>
                                    <input
                                        className={`input-text ${errors.nome ? 'error' : ''}`}
                                        type="text"
                                        placeholder="Digite seu nome"
                                        {...register("nome")} />
                                    {errors.nome && (
                                        <p className="input-label-error">
                                            {errors.nome.message}
                                        </p>
                                    )}
                                </div>

                                <div>
                                    <p className='login-label'>Celular</p>
                                    <InputMask
                                        className={`input-text ${errors.email ? 'error' : ''}`}
                                        mask={"+55 (99) 99999-9999"}
                                        maskChar={null}
                                        {...register("celular")} />
                                    {errors.celular && (
                                        <p className="input-label-error">
                                            {errors.celular.message}
                                        </p>
                                    )}
                                </div>

                                <div className="password-input-container">
                                    <p className='login-label'>Senha</p>
                                    <input
                                        className={`input-text ${errors.senha ? 'error' : ''}`}
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Digitar senha"
                                        {...register("senha")} />
                                    {errors.senha && (
                                        <p className="input-label-error">
                                            {errors.senha.message}
                                        </p>
                                    )}
                                    <IconButton onClick={togglePasswordVisibility} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </div>

                                <div className="password-input-container">
                                    <p className='login-label'>Confirmar senha</p>
                                    <input
                                        className={`input-text ${errors.confirmSenha ? 'error' : ''}`}
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Confirmar senha"
                                        {...register("confirmSenha")} />
                                    {errors.confirmSenha && (
                                        <p className="input-label-error">
                                            {errors.confirmSenha.message}
                                        </p>
                                    )}
                                    <IconButton onClick={togglePasswordVisibility} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </div>

                                <button className='primary-button' type='submit'>
                                    Criar conta
                                </button>

                                <p className="login-subtitle">Ao prosseguir, você concorda com nossos Termos de Uso
                                    e Política de Privacidade.</p>

                                <div className="login-register">
                                    Já possui uma conta? <a href="/login">Acesse aqui.</a>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;
